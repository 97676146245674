.font-t1 {
    font-size: 1.5rem;
    font-weight: 600;
}

.font-t2 {
    font-size: 1.8rem;
    font-weight: 400;
    color: dimgray;
}

.font-l1 {
    font-size: 1rem;
    font-weight: 500;
    color: dimgray;
}

.font-c1 {
    font-size: 1.2rem;
    font-weight: 400;
    color: black;
}

/* flexbox */
.d-flex-col {
    display: flex;
    flex-direction: column;
}

.d-flex-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.f-grow-1 {
    flex-grow: 1;
}

.f-ai-center {
    align-items: center;
}

/* tabla */
table {
    border-spacing: 0;
}

th,
td {
    padding: 12px 8px 0 8px;
}

td {
    word-break: break-all;
}

/* Solo para apoyo */
.bg-r {
    background: red;
}

.bg-g {
    background: green;
}

.bg-b {
    background: blue;
}

.bg-y {
    background: yellow;
}

@media screen and (max-width: 450px) {
    .ocultar-div {
        display: none;
        visibility: hidden;
        position: absolute;
    }
}