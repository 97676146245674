.section-container {
    padding: 20px;
}

.card-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-content: flex-start;
    align-items: flex-start;
    gap: 24px;
}