ul li {
  color: white;
}

ul li span {
  color: #222;
}

ol li {
  font-size: 14px;
}

ol li span {
  font-size: 20px;
  margin-left: 8px;
  color: black;
}

.topBar {
  background: #36B4EE;
  height: 40px;
  font-size: 14px;
  font-family: 'Open Sans', sans-serif;
  color: white;
  padding: 0 20px 0 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.centerContent {
  display: flex;
  align-items: center;
  justify-content: center;
}

.small-icon {
  font-size: 1.25rem;
  font-weight: lighter;
  line-height: 0;
  text-align: center;
  vertical-align: middle;
}

.large-icon {
  font-size: 3rem;
  font-weight: lighter;
  line-height: 1;
  text-align: center;
  vertical-align: middle;
  margin: 20px 10px 20px 10px;
  color: white;
}

.header {
  background: white;
  height: 75px;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1030;
  /* box-shadow: 0px 2px 15px rgb(0 0 0 / 15%); */
}

.header-fix-top {
  background: white;
  height: 75px;
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
  box-shadow: 0px 2px 15px rgb(0 0 0 / 11%); 
}

.header-div {
  margin: 0 32px 0 32px;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  gap: 8px;
}

.header-logo {
  height: auto;
  width: 100%;
}

.banner-font {
  text-align: left;
  font-family: 'Open Sans', sans-serif;
  font-size: 18px;
  font-weight: 600;
  color: #222;
  line-height: 1.4;
}

.banner-font-title {
  font-family: 'Poppins', sans-serif;
  font-size: 52px;
  font-weight: 700;
  color: black;
}

.banner-font-title-2 {
  font-weight: 300;
}

.banner-bottom-svg {
  width: 100%;
  max-height: initial;
  fill: white;
  vertical-align: middle;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 100;
}

.item {
  background: #36B4EE;
  flex-grow: 1;
  min-width: 350px;
  padding: 20px;
}

.item-img-bkgd {
  background-size: cover;
  background: url(../../../public/images/banner.jpg) no-repeat center;
  background-size: cover;
  position: relative;
}

.item-content {
  position: relative;
  height: 100%;
  z-index: 200;
}

.item-content-title {
  font-size: 24px;
  font-weight: 700;
  color: black;
}

.item-content-flex {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: flex-end;
  line-height: 1;
  gap: 10px;
}

.item-content-shadow {
  border-style: solid;
  border-width: 1px;
  border-color: rgba(255, 255, 255, 0.2);
  height: 100%;
  background: rgba(255, 255, 255, 0.1);
  display: flex;
  flex-direction: row;
}